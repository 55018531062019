<template>
  <div class="form-detail-wraper">
    <div class="forum-info">
      <div class="forum-info-container">
        <img :src="themeDetail.picUrl" alt />
        <div class="info-wraper">
          <h1 class="tag-name">
            {{themeDetail.themeName}}
            <span>回复 {{replyNum || 0}}</span>
          </h1>
          <h2>{{themeDetail.themeInfo}}</h2>
        </div>
      </div>
    </div>
    <div class="forum-post-detail">
      <div class="user-avatar">
        <img :src="stickInfo.portraitUrl || defaultHead" alt />
        <span>发帖人</span>
      </div>
      <div class="post-info">
        <h2>
          {{stickInfo.stickTitle}}
          <span>来自：{{stickInfo.username}}</span>
          <span>{{stickInfo.createTime}}</span>
        </h2>
        <div class="post-content" v-html="stickInfo.stickInfo"></div>
      </div>
    </div>
    <div class="forum-post-recomment">
      <h2>回应</h2>
      <div class="recommnet-item" v-for="replyInfo in replyInfos" :key="replyInfo.replyId">
        <img :src="replyInfo.portraitUrl|| defaultHead" alt />
        <div class="recomment-detail">
          <h3>
            {{replyInfo.username}}
            <span>{{replyInfo.createTime}}</span>
            <button class="reply-btn" @click="replyStick(replyInfo)">回应</button>
          </h3>
          <div
            class="quote"
            v-if="replyInfo.parentReplyInfo"
          >{{ replyInfo.parentIsDelete == 0 ? replyInfo.parentReplyInfo :'回复已删除'}}</div>
          <div>{{replyInfo.replyInfo}}</div>
        </div>
      </div>
    </div>
    <div class="reply-container">
      <h2>你的回应</h2>
      <div class="quote-content">{{replyInfo.replyInfo}}</div>
      <div class="reply-textarea">
        <textarea
          v-model="comment"
          placeholder="请输入您的回复"
          ref="commentTextarea"
          id
          cols="30"
          rows="10"
        ></textarea>
      </div>
      <div class="button-wraper">
        <button @click="sendComment">发送</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getStickInfo,
  getThemeList,
  getReplyInfo,
  saveReplyInfo
} from "../assets/js/api";
export default {
  data() {
    return {
      stickInfo: {},
      replyInfos: [],
      replyInfo: {},
      themeDetail: {},
      page: 1,
      limit: 10,
      replyNum: 0,
      comment: "",
      defaultHead: require("../assets/images/moren.png")
    };
  },
  methods: {
    getReplyInfos() {
      getReplyInfo({
        page: this.page,
        limit: this.limit,
        stickId: this.stickId
      }).then(result => {
        if (result.code == 0) {
          this.replyInfos = result.Page.records;
          this.replyNum = result.Page.total;
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    sendComment() {
      saveReplyInfo({
        replyInfo: this.comment,
        stickId: this.stickId,
        parentReplyId: this.replyInfo.replyId || 0
      }).then(result => {
        if (result.code == 0) {
          this.comment = "";
          this.replyInfo = {};
          this.getReplyInfos();
        }
      });
    },
    replyStick(replyInfo) {
      this.replyInfo = replyInfo;
      this.$refs.commentTextarea.focus();
    }
  },
  mounted() {
    this.stickId = this.$route.params.id;
    this.themeId = this.$route.params.themeId;
    if (JSON.parse(sessionStorage.getItem("themeDetail"))) {
      this.themeDetail = JSON.parse(sessionStorage.getItem("themeDetail"));
    } else {
      getThemeList().then(result => {
        if (result.code == 0) {
          this.themeDetail = result.list.find(
            item => item.themeId == this.themeId
          );
        }
      });
    }
    getStickInfo({
      stickId: this.stickId
    }).then(result => {
      this.stickInfo = result.stick;
      this.themeId = result.stick.themeId;
    });
    this.getReplyInfos();
  },
  destroyed() {
    if (window.loading) {
      window.loading.close();
    }
  }
};
</script>
<style>
a {
  text-decoration: underline;
}
</style>
<style lang="scss" scoped>
.form-detail-wraper {
  width: 1200px;
  min-height: calc(100% - 180px);
  margin: 0 auto;
}
.forum-info {
  margin-top: 15px;
  height: 130px;
  background: #f7f7f7;
}
.forum-info-container {
  padding: 10px 20px;
  display: flex;
  img {
    margin-right: 10px;
    width: 120px;
    height: 100px;
    background: #00d59d;
  }
  .info-wraper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 39px;
    color: #666666;
  }
  .tag-name {
    color: #222222;
    font-size: 18px;
    span {
      color: #666666;
      margin-left: 50px;
      letter-spacing: 1px;
    }
  }
}
.forum-post-detail {
  margin-top: 20px;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  .user-avatar {
    margin-right: 20px;
    width: 125px;
    height: 125px;
    position: relative;
    img {
      width: 100%;
      max-height: 100%;
    }
    span {
      background: #00d59d;
      height: 26px;
      width: 48px;
      display: block;
      border-radius: 3px;
      transform: rotate(-36deg);
      position: absolute;
      top: 8px;
      left: -11px;
      color: #fff;
      line-height: 26px;
      text-align: center;
      font-size: 12px;
    }
  }
  .post-info {
    flex: 1;
    h2 {
      font-size: 18px;
      font-weight: bold;
      span {
        display: inline-block;
        color: #999999;
        line-height: 38px;
        font-weight: 500;
        min-width: 95px;
      }
    }
    .post-content {
      color: #333333;
    }

    .post-image-wraper {
      margin-top: 20px;
      img {
        margin-right: 10px;
        width: 200px;
        height: 140px;
      }
    }
  }
}
.forum-post-recomment {
  padding-top: 20px;
  h2 {
    color: #333333;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 5px;
  }
}
.recommnet-item {
  display: flex;
  img {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    margin-right: 20px;
    margin-top: 26px;
  }
  .recomment-detail {
    flex: 1;
    padding: 25px 0;
    border-bottom: 1px solid #eeeeee;
    h3 {
      font-size: 14px;
      line-height: 40px;
      color: #333333;
      span {
        color: #999999;
      }
      .reply-btn {
        background: #00d59d;
        color: #fff;
        width: 72px;
        height: 32px;
        line-height: 32px;
        border: none;
        outline: none;
        border-radius: 4px;
        float: right;
        cursor: pointer;
      }
    }
    div {
      font-size: 16px;
      line-height: 35px;
    }
    .quote {
      color: #666666;
      font-size: 14px;
    }
  }
}
.reply-container {
  padding: 21px;
  box-sizing: border-box;
  background: #f7f7f7;
  h2 {
    font-size: 16px;
    color: #333333;
    margin-bottom: 15px;
  }
}
.quote-content,
.reply-textarea {
  margin-bottom: 20px;
  background: #fff;
  textarea {
    border: none;
    width: 100%;
    text-indent: 10px;
    padding-top: 5px;
  }
}
.quote-content {
  padding: 20px;
}
.button-wraper button {
  margin: 0 auto;
  display: block;
  width: 180px;
  height: 32px;
  background: rgba(0, 213, 157, 1);
  border: 1px solid rgba(0, 213, 157, 1);
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
</style>